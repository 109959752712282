import { render, staticRenderFns } from "./GuildIcon.vue?vue&type=template&id=249f3aaa&scoped=true&"
import script from "./GuildIcon.vue?vue&type=script&lang=js&"
export * from "./GuildIcon.vue?vue&type=script&lang=js&"
import style0 from "./GuildIcon.vue?vue&type=style&index=0&id=249f3aaa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "249f3aaa",
  null
  
)

export default component.exports